import React from "react";
import { PropagateLoader, PulseLoader } from "react-spinners";

const CustomButton = (
  props: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & { isInActive?: boolean; isLoading?: boolean }
) => {
  const { className, isInActive, children, isLoading, ...rest } = props;
  return (
    <button
      disabled={isInActive}
      className={`inline-flex min-w-[115px] sm:min-w-[130px] cursor-pointer items-center justify-center border px-2 sm:px-4 py-1.5 text-[13px] duration-200
      ${
        !isInActive
          ? "border-dx-theme-link text-dx-theme-link  hover:border-transparent  hover:bg-dx-theme-item-highlight  hover:shadow-md "
          : "border-dx-theme-border text-dx-theme-border"
      }
      
      ${className}
      `}
      {...rest}
    >
      {isLoading ? (
        <PulseLoader className="my-0.5" margin={4} size={7} color="#1660A5" />
      ) : (
        children
      )}
    </button>
  );
};

export default CustomButton;
