import * as React from "react";
import Header from "../components/common/header";
import Footer from "../components/common/footer";
import SEO from "../components/seo";
import CustomButton from "../components/common/button";
import CustomLink from "../components/common/custom_link";

const PageNotFound = () => {
  return (
    <>
      <Header />
      <section className="flex h-[90vh] w-full flex-col items-center justify-center gap-0 px-2 text-center">
        <h1 className="text-[128px] font-bold leading-none text-dx-theme-link">
          404
        </h1>
        <span className="text-[34px] font-normal text-dx-theme-link">
          NOT FOUND
        </span>
        <div className="text-center">
          <p className="py-5 text-[#6A6A6A]">
            申し訳ございません。
            <br />
            指定されたファイルまたはディレクトリは存在いたしません。
          </p>
        </div>
        <CustomLink to="/">
          <CustomButton isInActive={false}>トップページ</CustomButton>
        </CustomLink>

        {/* <Button to="/">トップページ</Button> */}
      </section>
      <Footer />
    </>
  );
};

export const Head = ({ location }) => {
  return (
    <SEO title={"Home"} description={"Description"} path={location.pathname} />
  );
};

export default PageNotFound;
